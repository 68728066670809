import React from 'react'
import './footer.css'

function Footer() {
  return (
    <footer className='footer'>
        Copyright &#169; 2024 Krishna Katwal. All right received. Developed by Krishna Katwal
    </footer>
  )
}

export default Footer
